<script setup lang="ts">
import { computedAsync } from '@vueuse/core';
import qs from 'qs';

import Utility from '~/services/Utility';
import { type Product } from '~/services/main';

// @ts-ignore
import UtilPayment from '../../UtilPayment';
import { type DesignModule, type DisplayProduct, type MainProductClickPayload } from './../type';

const props = defineProps<{
  product: Product | DisplayProduct;
  designModule?: DesignModule;
  productIndex?: number;
  eachProductIndex: number | null;
  hidePrice?: boolean;
}>();
const isClient = $isClient;
const {
  public: { frontUrl, apiBase },
} = useRuntimeConfig();

const emit = defineEmits<{
  (e: 'click', product: MainProductClickPayload): void;
}>();

const isEmpty = $isEmpty;
const $Utility = Utility;
const $router = useRouter();
const $route = useRoute();

const productMonthPrice = computed(() => {
  return UtilPayment.getMonthlyPrice(props.product as Product, props.product.monthPlan, null, true);
});

const discountPercentage = computed(() => {
  const price = props.product.price;
  const discountPrice = props.product.discountPrice;
  return UtilPayment.getDiscountPercentageValue(price, discountPrice);
});

function movePage() {
  emit('click', { ...props.product, discountPercentage: discountPercentage.value, salePrice: productMonthPrice.value });
}

const productLink = computed(() => {
  const queryString = qs.stringify({
    displaySeq: props.product.displaySeq!,
  });
  return `/product?${queryString}`;
});
</script>

<template>
  <CustomLink class="item-product" :to="productLink" @click="movePage">
    <div v-show="designModule ? designModule.tagViewYn == 'Y' : true" class="item-product__badge">
      <Tag v-if="product.todayEndYn == 'Y'" value="오늘마감" style="background: #e4515a; color: #ffffff"></Tag>
      <Tag v-if="product.alarmYn == 'Y'" value="오픈예정" style="background: #ffffff; color: #444444"></Tag>
      <Tag v-if="product.tomorrowEndYn == 'Y'" value="내일마감" style="background: #e4515a; color: #ffffff"></Tag>
    </div>

    <div class="item-product__image">
      <Image :src="Utility.getOptimized(product.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" alt="" loading="lazy" />
      <div v-if="$Utility.isEndProduct(product)" class="item-product__soldout">
        <strong>마감</strong>
      </div>
    </div>

    <div class="item-product__title">
      <strong
        v-show="productIndex == 0 && eachProductIndex != null && eachProductIndex < 4"
        :class="`item-product__ranking${(eachProductIndex ?? 0) + 1}`"></strong>
      <p>{{ product.displayName }}</p>
    </div>

    <div class="item-product__teacher" :class="product.reviewCount === 0 && 'iconhide'">
      <p>
        <template v-if="product.reviewCount > 0">
          <span class="item-product__teacher-score"
            >{{ product.reviewPoint }}({{ Utility.refineReviewCount(product.reviewCount) }})</span
          >
        </template>
        <strong v-if="!isEmpty(product.creatorNames)">{{ product.creatorNames?.join(', ') }}</strong>
      </p>
    </div>

    <!-- FIRST-1165 - AB테스트 결과 상품 썸네일에 가격이 표시되지 않도록 수정 -->
    <div
      v-if="false"
      v-show="designModule == null || (designModule && designModule.priceViewYn == 'Y')"
      class="item-product__price">
      <p>
        <strong v-if="discountPercentage != 0" class="discount-price-text">
          {{ $Utility.setCommaPer3Digit(discountPercentage) }}%
        </strong>
        <span v-if="$Utility.hasMonthlyPlan(product.monthPlan)">월&nbsp;</span>
        {{ $Utility.setCommaPer3Digit(productMonthPrice) }}원
      </p>
    </div>

    <div v-show="designModule == null || (designModule && designModule.tagViewYn == 'Y')" class="item-product__tag">
      <Tag v-if="product.originalYn == 'Y'" value="ORIGINAL" style="background: #000000; color: #ffffff"></Tag>
      <Tag
        v-for="(tag, index) in product.displayTags"
        :key="index"
        :value="tag.tagName"
        :style="{
          background: $Utility.getHex(tag.tagBackColor),
          color: $Utility.getHex(tag.tagFontColor),
        }"></Tag>
    </div>
  </CustomLink>
</template>

<style scoped lang="scss">
.item-product {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 20px 0;
  cursor: pointer;
}

.item-product__image {
  position: relative;
  margin-bottom: 12px;
  z-index: 10;
  overflow: hidden;
  border-radius: 8px;
}
.item-product__image::before {
  content: '';
  display: block;
  padding-top: 56.5%;
}
.item-product__image :deep(.p-image) img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.2s ease;
}

.item-product__title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}
.item-product__title strong {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
  font-size: 0;
}
.item-product__title p {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c2a4b;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  max-height: 44px;
  @media screen and (max-width: $md) {
    font-size: 14px;
    line-height: 20px;
  }
}
.item-product__ranking4::before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background: url(/icon/4.svg) no-repeat 50% 50%;
}
.item-product__teacher {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.item-product__teacher p {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  line-height: 16px;
  color: #aaa;
}
.item-product__teacher p strong {
  font-weight: 500;
  color: #8790a1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  max-height: 22px;
}
.item-product__teacher-score {
  display: flex;
  align-items: center;
  margin-right: 6px;
  color: #8790a1;
}
.item-product__tag {
  font-size: 0;
  :deep(.p-tag) {
    margin: 0 4px 4px 0;
  }
}
</style>
../type
